import GridLayout from "layouts/gridLayout"
import React from "react"

//
// Visualises the colr palette
//
const Palette = (props) => {
  return (
    <GridLayout>
      <p
        className="
          col-span-12 
          my-10
          font-bold text-xl
          text-grey-darker
          "
      >
        Palette
      </p>

      {/* Grays */}
      <div className="col-span-3 mb-10 p-8 bg-grey-light text-center text-grey-darker border border-grey">
        Light
      </div>
      <div className="col-span-3 mb-10  p-8 bg-grey text-center text-grey-darker">
        Default
      </div>
      <div className="col-span-3 mb-10  p-8 bg-grey-dark text-center text-grey-light">
        Dark
      </div>
      <div className="col-span-3 mb-10  p-8 bg-grey-darker text-center text-grey-light">
        Darker
      </div>

      {/* Grey Shadows */}
      <div className="col-span-3 mb-10 p-8 bg-grey-shadow-light text-center text-grey-darker">
        Shadow Light
      </div>
      <div className="col-span-3 mb-10  p-8 bg-grey-shadow-dark text-center text-grey-darker">
        Shadow Dark
      </div>

      {/* Reds */}
      <div id="reds" className="col-span-12 flex">
        <div className="p-8 mr-2 mb-8 bg-red-lightest text-red-darkest">
          Lightest
        </div>
        <div className="p-8 mr-2  mb-8  bg-red-lighter text-center text-red-darkest">
          Lighter
        </div>
        <div className="p-8 mr-2  mb-8  bg-red-light text-center text-red-darkest">
          Light
        </div>
        <div className="p-8 mr-2  mb-8  bg-red text-center text-red-darkest">
          Default
        </div>
        <div className="p-8 mr-2  mb-8  bg-red-dark text-center text-red-lightest">
          Dark
        </div>
        <div className="p-8 mr-2  mb-8  bg-red-darker text-center text-red-lightest">
          Darker
        </div>
        <div className="p-8  mb-8 bg-red-darkest text-center text-red-lightest">
          Darkest
        </div>
      </div>

      {/* Greens */}
      <div id="greens" className="col-span-12 flex">
        <div className="p-8 mr-2  mb-8 bg-green-lightest text-green-darkest">
          Lightest
        </div>
        <div className="p-8 mr-2 mb-8   bg-green-lighter text-center text-green-darkest">
          Lighter
        </div>
        <div className="p-8 mr-2  mb-8  bg-green-light text-center text-green-darkest">
          Light
        </div>
        <div className="p-8 mr-2 mb-8   bg-green text-center text-green-darkest">
          Default
        </div>
        <div className="p-8 mr-2 mb-8   bg-green-dark text-center text-green-lightest">
          Dark
        </div>
        <div className="p-8 mr-2 mb-8   bg-green-darker text-center text-green-lightest">
          Darker
        </div>
        <div className="p-8  mb-8 bg-green-darkest text-center text-green-lightest">
          Darkest
        </div>
      </div>

      {/* Yellows */}
      <div id="yellows" className="col-span-12 flex">
        <div className="p-8 mr-2  mb-8 bg-yellow-lightest text-yellow-darkest">
          Lightest
        </div>
        <div className="p-8 mr-2 mb-8   bg-yellow-lighter text-center text-yellow-darkest">
          Lighter
        </div>
        <div className="p-8 mr-2  mb-8  bg-yellow-light text-center text-yellow-darkest">
          Light
        </div>
        <div className="p-8 mr-2 mb-8   bg-yellow text-center text-yellow-darkest">
          Default
        </div>
        <div className="p-8 mr-2 mb-8   bg-yellow-dark text-center text-yellow-lightest">
          Dark
        </div>
        <div className="p-8 mr-2 mb-8   bg-yellow-darker text-center text-yellow-lightest">
          Darker
        </div>
        <div className="p-8  mb-8 bg-yellow-darkest text-center text-yellow-lightest">
          Darkest
        </div>
      </div>

      {/* Blues */}
      <div id="blues" className="col-span-12 flex">
        <div className="p-8 mr-2  mb-8 bg-blue-lightest text-blue-darkest">
          Lightest
        </div>
        <div className="p-8 mr-2 mb-8   bg-blue-lighter text-center text-blue-darkest">
          Lighter
        </div>
        <div className="p-8 mr-2  mb-8  bg-blue-light text-center text-blue-darkest">
          Light
        </div>
        <div className="p-8 mr-2 mb-8   bg-blue text-center text-blue-darkest">
          Default
        </div>
        <div className="p-8 mr-2 mb-8   bg-blue-dark text-center text-blue-lightest">
          Dark
        </div>
        <div className="p-8 mr-2 mb-8   bg-blue-darker text-center text-blue-lightest">
          Darker
        </div>
        <div className="p-8  mb-8 bg-blue-darkest text-center text-blue-lightest">
          Darkest
        </div>
      </div>
    </GridLayout>
  )
}

export default Palette
